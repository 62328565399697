/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react'
import './style.css'
import IconSmall from '../../assets/icons/small-icon.png'
import classNames from 'classnames'
import { useContexts } from '../../contexts/globalContext'
import STATUS_CHAT from '../../constants/statusChat'
export default function Index({ hidden }) {
    const { status, setStatus, requiredPhone, questionAuto } = useContexts();
    const [showQuestionSuggest, setShowQuestionSuggest] = useState(true)
    const handleClick = () => {
        if (requiredPhone) setStatus(STATUS_CHAT.CHATTING)
        else setStatus(STATUS_CHAT.REQUIRE_PHONE)
        setShowQuestionSuggest(false)
    }

    useEffect(() => {
        if(status === STATUS_CHAT.BEFOR_CHAT) setShowQuestionSuggest(true)
    }, [questionAuto])
    return (
        <div className={classNames('wrap-logo relative', { hidden: !(status === STATUS_CHAT.BEFOR_CHAT) })}>
            <div className="small-logo-chat cursor-pointer" onClick={handleClick}>
                <img
                    src={IconSmall}
                    alt="icon-chat"
                    width="90px"
                    height="80px"
                />
            </div>
            {
                showQuestionSuggest
                &&
                <div className="mesenger-auto absolute" onClick={handleClick}>
                    <p className="mesenger-auto-text cursor-pointer break-word">
                        {questionAuto?.question}
                    </p>
                </div>
            }

        </div>

    )
}

import React from 'react'
import Normal from './Normal'
import QuestionSuggest from './QuestionSuggest'
import Rating from './Rating'
import './style.css'
import IconSmall from '../../../assets/icons/small-icon.png'
import TYPE_BOT_MESSAGE from '../../../constants/typeMessenger'
import MessengerAuto from './MessengerAuto'
import Typing from './Typing'

export default function Index({ isShowIcon = true, type,  ...rest }) {

    const getMessenger = (type) => {
        switch (type) {

            case TYPE_BOT_MESSAGE.NORMAL:
                return <Normal {...rest} />;

            case TYPE_BOT_MESSAGE.RATING:
                return <Rating {...rest} />;

            case TYPE_BOT_MESSAGE.SUGGEST:
                return <QuestionSuggest {...rest} />;
            case TYPE_BOT_MESSAGE.AUTO:
                return <MessengerAuto {...rest} />;
            case TYPE_BOT_MESSAGE.TYPING:
                return <Typing {...rest} />;
            default:
                return <Normal {...rest} />;
        }
    }

    let typeMes = type;
    if(!typeMes && rest?._meta?.buttons?.length>0 ){
        typeMes = TYPE_BOT_MESSAGE.SUGGEST
    }
    return (
        <>
            <div className="bot-messenger flex w-full items-start">
                <div className="flex">
                    {
                        isShowIcon &&
                        <div>
                            <img alt="icon-chat" src={IconSmall} width="25px" height="auto" />
                        </div>
                    }
                </div>
                <div className="flex flex-col">
                    {getMessenger(typeMes)}
                </div>

                {/* <Normal /> */}
                {/* <QuestionSuggest /> */}
                {/* <Rating /> */}

            </div>
        </>
    )
}

export const listQuestionSuggest = [
    'Đánh giá Timi',
    'Công ty có tuyển remote không?',
    'Ftech AI đóng bảo hiểm xã hội không?',
    'Phỏng vấn có bài test không?',
    'Chế độ thưởng bên mình ra sao?',
    'Danh mục chính'
]

export const listAutoQuestionSuggest = [
    {
        question:`Chào bạn. Mình là trợ lý ảo Ftech.ai. Mình có thể giúp gì cho bạn không?`,
        // question:`Title\n\t+ Type 1\n\t+ Type 2`,
        answer: ""
    },
    {
        question:"Bạn muốn biết Ftech bao nhiêu nhân sự không ?",
        answer: "Ftech đang là nơi làm việc của gần 200 nhân sự đó."
    },
    {
        question:"Bạn muốn biết trang Facebook của ftech chứ?",
        answer: "Mình gửi bạn trang facebook của Ftech nhé: https://www.facebook.com/FTECHAI/"
    },
    {
        question:"Bạn biết thời gian thử việc ở Ftech không?",
        answer: "Ftech AI có thời gian  thử việc 2 tháng bạn nhé."
    },
    {
        question:"Bạn quan tâm đến chế độ thưởng ở Ftech chứ?",
        answer: "FTech AI có chế độ thưởng Tết, thưởng quý, thưởng nóng tùy theo đóng góp của mỗi cá nhân ạ."
    },
    {
        question:"Bạn có biết thời gian làm việc ở Ftech chứ?",
        answer: "Ftech có thời gian làm việc 8h30 - 17h30, từ thứ 2 - thứ 6, nghỉ thứ 7 và chủ nhật."
    },
    {
        question:"Bạn có biết các lĩnh vực mà Ftech hướng đến chứ?",
        answer: "FTech AI có rất nhiều dự án về Game, AI và các phần mềm thông minh ạ."
    },
    {
        question:"Nói chuyện với mình để biết thêm thông tin BHXH ở Ftech nhé!",
        answer: ""
    },
    {
        question:"Bạn quan tâm đến chế độ OT hãy nói chuyện với mình nhé.",
        answer: ""
    },
    {

        question:"Nói chuyện với mình để biết thêm mức lương khi thử việc nhé.",
        answer: ""
    }
    
]

export function getRandomQuestionSuggest() {
    let min = 0;
    let max = listAutoQuestionSuggest.length - 1;
    let randomIndex = Math.floor(Math.random() * (max - min + 1)) + min
    return listAutoQuestionSuggest[randomIndex]
}